<template>
  <div>
    <van-nav-bar
      title="HSE积分获取"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="form-box">
      <div class="form-box infoIcon">
        <div class="form-box infoIcon errInfo">
          <span>积分已完成，无法重复获取！</span>
        </div>

        <div class="work-type-box">
          <div class="left-box">
            <p>
              积分日期：<span style="color: #2e2e4d">{{ data.pointDate }}</span>
            </p>
            <p>
              积分事由：<span style="color: #2e2e4d">{{ data.content }}</span>
            </p>
            <p>
              积分依据：<span style="color: #2e2e4d">{{
                data.classifyName
              }}</span>
            </p>
            <p>
              得分：<span style="color: #2e2e4d">{{ data.score }}</span>
            </p>
            <p>
              更新人：<span style="color: #2e2e4d">{{ data.uuserName }}</span>
            </p>
            <p>
              更新时间：<span style="color: #2e2e4d">{{ data.utime }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    };
  },
  created() {
    if (this.$route.params && this.$route.params.data) {
      this.data = this.$route.params.data;
    }
  }
};
</script>
<style lang="scss" scoped>
.form-box {
  margin-top: 18vw;
  .infoIcon {
    margin-top: 25vw;
    text-align: center;
    .errInfo {
      width: 250px;
      margin: 0 auto;
      height: 30px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 1000;
      color: #2e2e4d;
      line-height: 30px;
      margin-top: 3vw;
    }
  }
  .work-type-box {
    text-align: left;
    padding: 12px 16px;
    margin: 30px 16px;
    background-color: #f5f7fa;
    border-radius: 6px;
    .left-box {
      word-break: break-all;
      font-size: 14px;
      color: #8c8f97;
      line-height: 30px;
      font-weight: 400;
    }
  }
}
</style>
